import { Link } from "gatsby"
import React, { useContext } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

import {

  AiRapidData,
  QuickbaseServicesData,
  AboutQuandaryData
} from "../components/internal-data/faq-data"


import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"

import {
  Accordion,
  AccordionContext,
  Card,
  useAccordionToggle,
} from "react-bootstrap"




const Banner = () => {
  //   const { heroImage } = useStaticQuery(
  //     graphql`
  //       {
  //         heroImage: file(relativePath: { eq: "new-images/contact-us-bg.jpg" }) {
  //           childImageSharp {
  //             gatsbyImageData(
  //               width: 1920
  //               layout: CONSTRAINED
  //               formats: [AUTO, WEBP, AVIF]
  //             )
  //           }
  //         }
  //       }
  //     `
  //   )

  //   const heroImageData = getImage(heroImage)

  //   // Use like this:
  //   const bgImage = convertToBgImage(heroImageData)

  return (
    <div className="hero-banner relative pt-4 overflow-hidden">
      <div className="hero-overlay relative">
        <StaticImage
          placeholder="none"
          className="wave-img"
          src="https://cdn.sanity.io/images/55lcecww/production/5ef5b5149a6edf0ad2c67cbe3874446d1cee06e2-904x650.png"
          alt="System Integration"
          layout="constrained"
          style={{ height: "fit-content", zIndex: 0 }}
          fetchpriority="low"
        />
      </div>
      <div className="container relative my-auto" style={{ zIndex: 1 }}>
        <div className="row pb-3 align-items-center">
          <div className="col-lg-7 col-xl-7 col-md-7">
            <div
              className="hero-custom-content-home review-content text-white"
              style={{ padding: "20px 0", marginTop: "5.3rem" }}
            >
              <h1
                className="page-title text-left"
                style={{
                  marginBottom: "0.5rem",
                  display: "inline",
                }}
              >
                Frequently Asked Questions
              </h1>
              <p className="mb-0 mt-2 text-left">
                Get answers to all your questions on how to operationalize AI,
                the innovation analysis, rapid innovation, and software asset
                management.
              </p>
              {/* {item?.url && ( */}
              <div className="hero-button">
                <Link
                  // to={`${item?.url}`}
                  to="/contact"
                  className="btn btn-primary btn-arrow mr-2 mt-4 text-center text-capitalize py-3"
                  style={{ marginTop: "auto" }}
                >
                  {/* {item?.label} */}
                  LET'S CHAT
                </Link>
              </div>
              {/* )} */}
            </div>
          </div>
          <div
            className="col-lg-5 col-md-5 pb-2 d-flex justify-content-end"
            style={{ position: "relative" }}
          >
            <StaticImage
              placeholder="none"
              className="rounded d-flex w-100"
              src="https://cdn.sanity.io/images/55lcecww/production/35d64f010f1760536220e421237e535bd781e220-1800x1800.png"
              alt="System Integration"
              layout="constrained"
              style={{ height: "fit-content", zIndex: 1 }}
              fetchpriority="low"
            />
            {/* <DesignElement />
              <DesignElement2 /> */}
          </div>
        </div>
      </div>
      <div className="swiperOverlay"></div>
    </div>
  )
}

function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext)

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  )

  const isCurrentEventKey = currentEventKey === eventKey

  return (
    <Card.Header
      className={isCurrentEventKey ? "accordion-active" : "accordion-collapsed"}
    >
      <button
        aria-label="btn"
        type="button"
        onClick={decoratedOnClick}
        className={`m-0 border-0 bg-transparent w-100 text-left ${isCurrentEventKey ? "text-primary-gradient" : ""
          }`}
      >
        <p className="h5 text-left toggle-btn">{children}</p>
        <div>
          <div className="btn-plus-minus" />
        </div>
      </button>
    </Card.Header>
  )
}

const FAQ = ({ location }) => {
  return (
    <>
      <Layout banner={<Banner />} location={location}>
        <Seo
          title="Frequently Asked Questions | Quandary Consulting Group"
          description="These are the most commonly asked questions our customers have before they start working with us. Can't find your question? Contact us."
        />
        <section className="faq-wrapper" style={{ padding: "5rem 0" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-9 mx-auto">
                <h2 className="my-0">AI + Rapid Innovation Services</h2>
                <p className="mb-2">Quandary helps companies rapidly innovate using low-risk, low-code tools. We work with executive teams to identify where new technology (like AI) can streamline operations, reduce costs, and drive growth.</p>
                <Accordion defaultActiveKey="0">
                  <Card>
                    <ContextAwareToggle eventKey="1" className="bg-transparent">
                      How do you help companies innovate without disrupting operations?
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body>
                        <div className="mt-0">
                          We pinpoint low-risk opportunities (often buried in your existing workflows) where AI or automation can deliver outsized ROI.
                        </div>
                        <div className="mt-3">
                          Then, we deliver working solutions in short sprints—without disrupting teams or creating new tech debt.
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <ContextAwareToggle eventKey="2" className="bg-transparent">
                      What is your Innovation Analysis, and why does it matter?
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="2">
                      <Card.Body>
                        <div className="mt-0">
                          It’s a benchmark that shows where your business stands on innovation compared to competitors.
                        </div>
                        <div className="mt-3">
                          We identify which systems, processes, or ideas are holding you back and where small changes could lead to major efficiency gains.
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <ContextAwareToggle eventKey="3" className="bg-transparent">
                      What does a typical innovation engagement look like?
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="3">
                      <Card.Body>
                        <div className="mt-0">
                          We start with analysis, build a roadmap, and deliver working solutions in 4–6 weeks. These aren’t theoretical strategies. They’re tested changes that impact your bottom line.
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <ContextAwareToggle eventKey="4" className="bg-transparent">
                      We’ve tried to innovate before. It didn’t stick. How is this different?
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="4">
                      <Card.Body>
                        <div className="mt-0">
                          Most innovation fails when it’s overbuilt, misaligned with business goals, or handed off to teams who can’t maintain it.
                        </div>
                        <div className="mt-3">
                          We avoid all three. We validate fast, keep scope tight, and only recommend what delivers ROI.
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <ContextAwareToggle eventKey="5" className="bg-transparent">
                      What makes your approach ‘low-risk’?
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="5">
                      <Card.Body>
                        <div className="mt-0">
                          No long timelines. No high-overhead software builds. No guesswork.
                        </div>
                        <div className="mt-3">
                          We start small, validate quickly with low-code applications, and move forward only if the data supports it. You stay in control at every stage.
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <ContextAwareToggle eventKey="6" className="bg-transparent">
                      Do I need internal technical staff to work with you?
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="6">
                      <Card.Body>
                        <div className="mt-0">
                          No. We work directly with executives and operations leaders. If you have a tech team, we’ll coordinate. If not, we’ll run lean and ensure our build is maintainable.
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <ContextAwareToggle eventKey="7" className="bg-transparent">
                      Where does AI move the needle for a company like ours?
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="7">
                      <Card.Body>
                        <div className="mt-0">
                          Most impact comes from cutting down on manual work, speeding up reporting, and streamlining decision-making.
                        </div>
                        <div className="mt-3">
                          We don’t push AI where it won’t help. We look for quick wins with measurable savings. Areas that yield high results: HR, Invoicing, Sales, and Reporting.
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <ContextAwareToggle eventKey="8" className="bg-transparent">
                      How do you avoid creating more systems we can’t support long-term?
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="8">
                      <Card.Body>
                        <div className="mt-0">
                          Everything we build is designed for handoff and long-term use. No black-box solutions. No custom code unless it’s necessary.
                        </div>
                        <div className="mt-3">
                          We build with sustainability in mind. We also build documentation and walk your team through adoption.
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <ContextAwareToggle eventKey="9" className="bg-transparent">
                      What does this cost?
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="9">
                      <Card.Body>
                        <div className="mt-0">
                          Less than most firms spend on a failed project.
                        </div>
                        <div className="mt-3">
                          We offer fixed-scope sprints with defined outcomes (usually a fraction of what you’d spend on traditional consulting or development).
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <ContextAwareToggle eventKey="10" className="bg-transparent">
                      What kind of results have clients seen?
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="10">
                      <Card.Body>
                        <div className="mt-0">
                          Clients typically save 20–30% on operational costs, eliminate redundant systems, and accelerate new initiatives.
                        </div>
                        <div className="mt-3">
                          We deliver business impact you can measure—not just dashboards and slide decks.
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>



              </div>
              <div className="col-lg-9 mx-auto">
                <h2 className="mb-0">Quickbase Services</h2>
                <p>We’re a trusted Quickbase Partner with deep expertise in building, securing, and scaling custom applications. Whether you’re starting from scratch or fixing a legacy build, we can help.</p>
                <Accordion defaultActiveKey="0">

                  <Card>
                    <ContextAwareToggle eventKey="1" className="bg-transparent">
                      What types of Quickbase projects do you handle?
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body>
                        <div className="mt-0">
                          We build, repair, and optimize Quickbase apps for operations-heavy teams.
                        </div>
                        <div className="mt-3">
                          This includes custom development, third-party integrations, audits of underperforming systems, and ongoing support to help clients scale with confidence.
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <ContextAwareToggle eventKey="2" className="bg-transparent">
                      Do you only work with Quickbase?
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="2">
                      <Card.Body>
                        <div className="mt-0">
                          No. We specialize in Quickbase but also work with tools like Workato, Pipefy, and TrackVia when they’re a better fit for the problem.
                        </div>
                        <div className="mt-3">
                          Our goal is to deliver impact—<em>not push one tool.</em>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <ContextAwareToggle eventKey="3" className="bg-transparent">
                      We already have a Quickbase app. Can you fix or improve it?
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="3">
                      <Card.Body>
                        <div className="mt-0">
                          Yes. Most clients come to us with apps that were rushed, overbuilt, or inherited. We audit your setup, identify what's slowing down your team, and fix the root issues.
                        </div>
                        <div className="mt-3">
                          Whether that’s rebuilding modules, untangling logic, or improving usability so your team can actually work faster, we can help.
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <ContextAwareToggle eventKey="4" className="bg-transparent">
                      What’s the difference between Quandary and a Quickbase-certified freelancer or other service providers?
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="4">
                      <Card.Body>
                        <div className="mt-0">
                          Most Quickbase providers act like order takers: they build what you request. This can lead to more problems if you don’t understand the business logic behind the system.
                        </div>
                        <div className="mt-3">
                          We act like a strategic partner. We challenge assumptions, rework poor architecture, and ensure what we build holds up under growth and change.
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <ContextAwareToggle eventKey="5" className="bg-transparent">
                      How do you handle integrations with other systems?
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="5">
                      <Card.Body>
                        <div className="mt-0">
                          We use tools like Workato to connect Quickbase with CRMs, ERPs, and other critical platforms.
                        </div>
                        <div className="mt-3">
                          Our focus is on secure, low-maintenance integrations that reduce manual work and improve visibility across teams.
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <ContextAwareToggle eventKey="6" className="bg-transparent">
                      Can you help with security or compliance issues in Quickbase?
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="6">
                      <Card.Body>
                        <div className="mt-0">
                          Yes. Our security audits uncover role misconfigurations, access vulnerabilities, and data risks.
                        </div>
                        <div className="mt-3">
                          We help teams tighten controls and document compliance processes, especially in regulated industries.
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <ContextAwareToggle eventKey="7" className="bg-transparent">
                      What’s included in a Quickbase Health Audit?
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="7">
                      <Card.Body>
                        <div className="mt-0">
                          We review your app’s performance, structure, user roles, security, and usage data. Then we deliver a prioritized roadmap for fixes, optimizations, and improvements.
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <ContextAwareToggle eventKey="8" className="bg-transparent">
                      What kind of training or support do you offer post-launch?
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="8">
                      <Card.Body>
                        <div className="mt-0">
                          We provide enablement support so your team can run and evolve the app without us.
                        </div>
                        <div className="mt-3">
                          This includes documentation, admin training, best practices, and optional advisory retainers if you want ongoing help.
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <ContextAwareToggle eventKey="9" className="bg-transparent">
                      What is PeakSuite, and who is it for?
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="9">
                      <Card.Body>
                        <div className="mt-0">
                          PeakSuite is a modular Quickbase framework built for companies that want a faster path to value. It’s a flexible starting point (customizable to your needs) without lengthy build.
                        </div>
                        <div className="mt-3">
                          It’s for organizations already using Quickbase, looking to quickly improve repeatable processes.
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <ContextAwareToggle eventKey="10" className="bg-transparent">
                      How do you price Quickbase projects?
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="10">
                      <Card.Body>
                        <div className="mt-0">
                          We offer fixed-fee, sprint-based pricing. Starting out, most clients invest between $10K–$50K depending on the scope, with clear deliverables and timelines.
                        </div>
                        <div className="mt-3">
                          Regardless of the cost, the goal is measurable ROI in weeks<strong>—not months.</strong>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                </Accordion>
              </div>
              <div className="col-lg-9 mx-auto">
                <h2 className="mb-0">About Quandary Consulting Group</h2>
                <p>Quandary helps growing companies innovate fast, reduce tech debt, and build better systems using modern tools. We act as a fractional innovation team for executive leaders who want to move faster without bloating headcount.</p>
                <Accordion defaultActiveKey="0">

                  <Card>
                    <ContextAwareToggle eventKey="1" className="bg-transparent">
                      What kinds of companies do you work with?
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body>
                        <div className="mt-0">
                          We partner with small to mid-sized companies (typically between $5M and $50M in revenue) that are growing quickly but constrained by outdated systems, siloed teams, or stalled internal projects.
                        </div>
                        <div className="mt-3">
                          However, we’ve worked with many Fortune 500 companies and global organizations. And we bring that insight and experience into every engagement.
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <ContextAwareToggle eventKey="2" className="bg-transparent">
                      What industries do you specialize in?
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="2">
                      <Card.Body>
                        <div className="mt-0">
                          We work across industries.
                        </div>
                        <div className="mt-3">
                          However, we’re especially strong in logistics, manufacturing, distribution, and tech-enabled businesses where operations and data flows are critical to scaling.
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <ContextAwareToggle eventKey="3" className="bg-transparent">
                      Why do companies choose Quandary instead of building solutions internally?
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="3">
                      <Card.Body>
                        <div className="mt-0">
                          Most internal teams are overloaded or too close to the problem.
                        </div>
                        <div className="mt-3">
                          We bring outside perspective, a proven framework, and execution speed. This allows leaders to move fast without adding permanent headcount or draining internal resources.
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <ContextAwareToggle eventKey="4" className="bg-transparent">
                      How are your projects typically structured?
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="4">
                      <Card.Body>
                        <div className="mt-0">
                          This depends on the project, budget, and business size.
                        </div>
                        <div className="mt-3">
                          In some cases, we work in fixed-scope sprints, starting with audits and roadmaps, then moving into implementation. Other cases, we can quickly mock up a solution without an agile methodology.
                        </div>
                        <div className="mt-3">
                          This gives you speed and clarity—without the risk of bloated retainers or open-ended consulting.
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <ContextAwareToggle eventKey="5" className="bg-transparent">
                      How long does it take to see results from working with Quandary?
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="5">
                      <Card.Body>
                        <div className="mt-0">
                          Most clients start seeing results in the first 2–6 weeks.
                        </div>
                        <div className="mt-3">
                          Whether it’s a new app, a reworked process, or an integrated system, we prioritize fast wins that show tangible value early—so you don’t wait months to see momentum.
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <ContextAwareToggle eventKey="6" className="bg-transparent">
                      Do you work well with internal IT or development teams?
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="6">
                      <Card.Body>
                        <div className="mt-0">
                          Yes. We often partner with internal teams to extend their capacity or handle strategic initiatives they don’t have time for.
                        </div>
                        <div className="mt-3">
                          We also serve as a bridge between ops leaders and IT when communication or priorities aren’t aligned.
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <ContextAwareToggle eventKey="7" className="bg-transparent">
                      What if we’ve already worked with another consultant and didn’t get results?
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="7">
                      <Card.Body>
                        <div className="mt-0">
                          That’s common. We’re often brought in after a failed project.
                        </div>
                        <div className="mt-3">
                          Our difference is in how we scope work (starting with the business case, not a tech wishlist) and how we build for long-term use, not just short-term delivery.
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <ContextAwareToggle eventKey="8" className="bg-transparent">
                      How do you measure success on a project?
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="8">
                      <Card.Body>
                        <div className="mt-0">
                          We tie every project to business outcomes: time saved, costs reduced, visibility improved, or execution speed increased.
                        </div>
                        <div className="mt-3">
                          <strong>If it doesn’t move the business forward, it doesn’t belong in scope.</strong>
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <ContextAwareToggle eventKey="9" className="bg-transparent">
                      Do we need a big budget to work with you?
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="9">
                      <Card.Body>
                        <div className="mt-0">
                          Not necessarily.
                        </div>
                        <div className="mt-3">
                          Most clients start small (with a focused sprint or audit) and grow from there once we’ve proven value. Our model is designed to minimize upfront risk and maximize ROI.
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                  <Card>
                    <ContextAwareToggle eventKey="10" className="bg-transparent">
                      What makes Quandary different from other consulting firms?
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="10">
                      <Card.Body>
                        <div className="mt-0">
                          We don’t just build what you ask for—we help you figure out what’s worth building.
                        </div>
                        <div className="mt-3">
                          We work fast, stay lean, and focus on business impact over complexity. No bloated decks. No wasted cycles. Just better systems, built for the way your business runs.
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>

                </Accordion>
              </div>


            </div>
          </div>
        </section>
        <section
          className="bg-case-study slogan-section"
          style={{
            paddingTop: "5rem",
            paddingBottom: "5rem",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-10 mx-auto text-center">
                <div>
                  <h2 className="text-white">
                    Still Have Questions? Reach out!
                  </h2>
                  <p className="text-white">We’ll get back to you ASAP.</p>

                  <Link
                    to="/contact/"
                    className="btn btn-primary btn-arrow mr-2 mt-4 text-center py-3 text-uppercase"
                    style={{ marginTop: "auto" }}
                  >
                    Let's Chat
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default FAQ
